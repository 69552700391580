const ParkingDuration = ({ duration }: { duration: number }) => {
  const totalMinutes = Math.round(duration / 60);

  // show duration in hours and minutes if it's more than 60 minutes
  if (totalMinutes >= 60) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return (
      <>
        {hours} hour{hours > 1 ? "s" : ""} {minutes} mins
      </>
    );
  }

  return <>{totalMinutes} mins</>;
};

export default ParkingDuration;
